import React, { useEffect, useState } from 'react'

const Time = () => {

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
      const interval = setInterval(() => setCurrentTime(new Date()), 1000);
      return () => clearInterval(interval); // Clean up interval on component unmount
    }, []);
  
    const formatTime = (date) => {
      const daysOfWeek = [
        'SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'
      ];
      const months = [
        'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC'
      ];
  
      const dayName = daysOfWeek[date.getDay()];
      const day = date.getDate();
      const month = months[date.getMonth()];
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
  
      return `${dayName} ${day} ${month} ${hours}:${minutes}:${seconds}`;
    };

  return (
    <div className='nbo__time-wrapper'>
        {formatTime(currentTime)}
    </div>
  )
}

export default Time