export const UnderlineLink = {
    marks: {
      link: ({ value, children }) => {
        return (
          <a
            href={value?.href}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {children}
          </a>
        );
      },
    },
  };