import { useState } from 'react'

const Service = (props) => {

    const {service, index} = props;

    const [isHovered, setIsHovered] = useState(false);
  return (
    <div className='nbo__service-wrapper'>
        <div className='nbo__service-name' 
        onPointerEnter={()=>setIsHovered(true)}
        onPointerLeave={()=>setIsHovered(false)}
        >
            <span className='nbo__service-index'>{index+1}</span>
            {service.serviceName}
        </div>
        <div className={`nbo__service-description ${isHovered?'active':''}`}>{service.serviceDescription}</div>
    </div>
  )
}

export default Service