import { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Home from "./views/Home";

import "./styles/global-styles.scss";

function App() {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
    return () => window.removeEventListener("resize", appHeight);
  }, []);

  useEffect(() => {
    const checkWidth = () => {
      if (window.innerWidth < 750) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    checkWidth();
    window.addEventListener("resize", checkWidth, false);
  });

  return (
    <Routes location={location} key={location.pathname}>
      <Route element={<Home key={"home"} {...{ isMobile }} />} path="/" />
    </Routes>
  );
}

export default App;
