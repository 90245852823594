import { useState, useEffect } from "react";
import sanityClient from "../client.js";

import Intro from "../components/Intro";
import Text from "../components/Text.js";
import Background from "../components/Background.js";

function Home(props) {
  const {isMobile} = props;
  const [pageData, setPageData] = useState(null);
  const [introDone, setIntroDone] = useState(false);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "home"][0]{...}`)
      .then((data) => {
        setPageData(data);
      })
      .catch();
  }, []);

  if(pageData){
    return (
        <>
          <Intro {...{ introDone, setIntroDone }} />
          <main className={`nbo__content-wrapper ${introDone?'active':''}`}>
            <Text {...{pageData, isMobile}} />
            <Background {...{pageData, isMobile}} />
          </main>
        </>
      );
  }
}

export default Home;
