import { PortableText } from "@portabletext/react";
import { UnderlineLink } from "../hooks/usePortableTextComponents";
import Time from "./Time";
import Services from "./Services";

const Text = (props) => {
  const { pageData, isMobile } = props;

  return (
    <>
      <div className="nbo__info-wrapper">
        {isMobile ? (
          <>
            <Time />
            <PortableText
              value={pageData.information}
              components={UnderlineLink}
            />
            <div className="nbo__clientele">
              <div className="nbo__clientele-half">
                <PortableText
                  value={pageData.clientele}
                  components={UnderlineLink}
                />
              </div>
              <div className="nbo__clientele-half">
                <PortableText
                  value={pageData.founders}
                  components={UnderlineLink}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <PortableText
              value={pageData.information}
              components={UnderlineLink}
            />
            <PortableText
              value={pageData.clientele}
              components={UnderlineLink}
            />
            <img
              src={`./assets/icons/NewbornOfficeYellow.svg`}
              alt="NEWBORN OFFICE"
              className="nbo__logo"
            />
          </>
        )}
      </div>

      {isMobile ? (
        <img
          src={`./assets/icons/NBYellow.svg`}
          alt="NEWBORN OFFICE"
          className="nbo__logo"
        />
      ) : (
        ""
      )}

      {!isMobile ? (
        <div className="nbo__founders-wrapper">
          <Time />
          <PortableText value={pageData.founders} components={UnderlineLink} />
        </div>
      ) : (
        ""
      )}

      <Services {...{ pageData }} />
    </>
  );
};

export default Text;
