import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import {useState, useEffect} from 'react'

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const Background = (props) => {

    const {pageData, isMobile } = props;

    const [backgroundImage, setBackgroundImage] = useState(null);

    useEffect(()=>{

        if(!isMobile){
            const selection = Math.floor(Math.random() * pageData.desktopBackground.length);
            setBackgroundImage(`${urlFor(pageData.desktopBackground[selection])}`);
        } else {
            const selection = Math.floor(Math.random() * pageData.mobileBackground.length);
            setBackgroundImage(`${urlFor(pageData.mobileBackground[selection])}`);
        }

    },[isMobile])

  return (
    <div className='nbo__background-wrapper' style={{backgroundImage: `url(${backgroundImage})`}} />
  )
}

export default Background