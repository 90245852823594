import React from 'react'
import Service from './Service';

const Services = (props) => {

    const { pageData } = props;
    
  return (
    <div className='nbo__services-wrapper'>
      <div className='nbo__services-title'>{pageData.servicesTitle}</div>
      <div className='nbo__services-list-wrapper'>
        {pageData.services.map((service, index)=>{
          return <Service service={service} index={index} key={index} />
        })}
      </div>
    </div>
  )
}

export default Services