import React, { useEffect } from "react";

const Intro = (props) => {
  const { introDone, setIntroDone } = props;

  useEffect(() => {



    setTimeout(() => {
      const logo = document.querySelector(".intro__logo");
      // logo.style.left = `-${logo.offsetWidth}px`;
      logo.style.left = `-20750px`;
    }, 300);
    setTimeout(() => {
      setIntroDone(true);
    }, 1800);
  }, []);

  return (
    <div className={`intro__wrapper ${introDone ? "disabled" : ""}`}>
      {/* <img src="./assets/icons/NewbornOfficeYellow.svg" alt="NEWBORN OFFICE" className='intro__logo'/>
    
    */}
      <div className="intro__logo">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          // width="100%"
          height="100%"
          viewBox="0 0 1671.021 77.351"
        >
          <g id="_1" transform="translate(0 -0.011)">
            <path
              id="Path_335"
              data-name="Path 335"
              d="M956.635,5.625c5.839,0,11.166-.014,16.488,0,19.515.055,39.04-.22,58.541.33,8.537.243,17.1,1.8,25.506,3.458,5.542,1.095,10.538,4.03,12.581,9.8,2.514,7.108-.888,14.39-8.015,17.67-2.528,1.163-5.267,1.882-8.171,2.895,3.6,1.063,7.058,1.823,10.314,3.1,6.362,2.5,10.74,6.723,10.818,14.074s-3.774,12.036-10.277,14.89c-8.565,3.756-17.683,4.969-26.88,5.13-23.706.417-47.412.522-71.123.737-3.169.027-6.343,0-9.787,0V5.625Zm14.912,66.57a13.517,13.517,0,0,0,1.846.261c17.477-.064,34.964.247,52.418-.4a98.029,98.029,0,0,0,22.95-4.072c6.806-1.942,9.792-6.6,9.467-11.725-.307-4.841-4.337-8.958-11.134-10.3a117.78,117.78,0,0,0-19.818-2.684c-17.693-.44-35.4-.3-53.105-.366a21.829,21.829,0,0,0-2.629.316V72.195Zm0-34.941c.38.174.577.343.774.343,19.95-.224,39.91-.243,59.851-.829,6.032-.179,12.146-1.392,17.367-5.143,6.009-4.319,5.8-12.068-.412-15.989-5.317-3.353-11.3-4.191-17.266-4.314-19.612-.412-39.232-.444-58.848-.609a9.173,9.173,0,0,0-1.466.279V37.25Z"
              transform="translate(-518.495 -3.031)"
              fill="#fcffac"
            />
            <path
              id="Path_336"
              data-name="Path 336"
              d="M724.611,6.274c-5.652,8.867-11.125,17.431-16.58,26.01-9.1,14.312-18.233,28.607-27.237,42.979-1.182,1.887-2.519,2.226-4.589,2.542-6.114.934-9.412-1.621-12.563-6.806-10.8-17.775-22.2-35.188-33.379-52.739-.728-1.14-1.47-2.272-2.34-3.614C619.2,28.382,610.611,41.934,602,55.477c-4.25,6.678-8.587,13.3-12.769,20.024-1.012,1.626-2.217,2-4.053,2.285-6.183.957-9.6-1.589-12.842-6.888C559.709,50.261,546.5,29.971,533.521,9.549c-.646-1.012-1.214-2.075-2.121-3.627,5.478,0,10.424-.137,15.348.119.966.05,2.079,1.5,2.734,2.546q17.566,28.215,35.028,56.5c.637,1.026,1.328,2.02,2.217,3.362.879-1.3,1.58-2.276,2.217-3.293Q606.551,36.965,624.1,8.734c1.264-2.047,2.656-2.62,5.043-2.963,7.351-1.053,11.409,1.864,15.123,8.276,10.081,17.4,21.059,34.29,31.693,51.378.577.925,1.237,1.8,2.121,3.087,3.371-5.4,6.526-10.442,9.682-15.49,8.8-14.1,17.83-28.066,26.266-42.374,2.611-4.429,5.6-5.533,10.58-4.378Z"
              transform="translate(-288.02 -3.007)"
              fill="#fcffac"
            />
            <path
              id="Path_337"
              data-name="Path 337"
              d="M1643.523,44.785c16.241,3.1,21.723,19.273,21.6,32.687h-16.186c-.275-2.812-.394-5.633-.843-8.4-2.015-12.412-9.366-19.689-22.176-21.8a75.149,75.149,0,0,0-11.509-1.008c-16.456-.151-32.912-.142-49.368-.183-.669,0-1.337.064-2.372.119V77.513H1547.65V5.951a22.542,22.542,0,0,1,2.56-.261c24.288.046,48.575-.041,72.858.247,9.87.119,19.7,1.218,28.877,5.409,8.276,3.778,11.6,9.471,10.7,18.366-.554,5.491-3.669,9.178-8.537,11.335-3.266,1.447-6.746,2.409-10.584,3.742ZM1562.64,40.15c20.134,0,39.864.156,59.586-.11a66.615,66.615,0,0,0,14.683-2.363c4.9-1.2,8.661-4.058,9.316-9.531.687-5.757-1.809-10.832-7.433-13.2-4.305-1.814-9.087-3.339-13.7-3.462-20.4-.545-40.821-.54-61.23-.714a4.465,4.465,0,0,0-1.228.357V40.154Z"
              transform="translate(-838.828 -3.078)"
              fill="#fcffac"
            />
            <path
              id="Path_338"
              data-name="Path 338"
              d="M2268.709,77.575c-23.463-.444-41.5-3.563-57.745-13.561-8.221-5.061-14.532-11.743-15.773-21.723-1.617-12.993,5.239-21.787,15.544-28.259C2227.4,3.567,2246.051.3,2265.37.407c15.32.082,30.3,2.579,44.151,9.462a61.064,61.064,0,0,1,14.07,9.3c12.586,11.546,12.375,28.3-.174,39.905-8.629,7.983-19.277,12.348-30.5,14.862-9.458,2.121-19.213,2.922-24.21,3.641Zm-8.217-5.281c15.824-.009,27.8-2.024,38.692-8.29,10-5.752,16.969-13.676,16.543-25.964-.385-11.1-6.724-18.627-15.833-24.1s-19.231-7.864-29.738-8.551c-14.615-.948-28.762.875-41.687,8.294-21.2,12.164-22.089,35.957-2.473,48.827,11.5,7.543,24.453,9.664,34.5,9.783Z"
              transform="translate(-1189.669 -0.213)"
              fill="#fcffac"
            />
            <path
              id="Path_339"
              data-name="Path 339"
              d="M1292.192.06c21.737.476,39.786,3.563,55.977,13.676,7.979,4.983,14.079,11.6,15.439,21.242,1.841,13.09-4.91,22.039-15.3,28.707-12.568,8.07-26.674,11.688-41.394,13.016-16.662,1.5-33.159.348-48.928-5.5a82.43,82.43,0,0,1-19.511-10.03c-16.735-12.068-16.667-32.394-.192-44.866,10.42-7.887,22.52-12.013,35.275-14.138C1280.7.981,1287.956.523,1292.192.06Zm3.339,72.08c14.152-.174,28.066-2.84,40.153-11.839,9.219-6.865,13.3-17.935,10.291-28.405-2.386-8.276-7.914-14.056-15.2-18.3-9.087-5.294-19.062-7.7-29.449-8.377-14.61-.957-28.794.806-41.669,8.3-9.687,5.643-16.593,13.488-16.525,25.4.069,11.771,6.985,19.415,16.593,24.979,10.891,6.307,22.9,8.148,35.816,8.239Z"
              transform="translate(-664.455 -0.026)"
              fill="#fcffac"
            />
            <path
              id="Path_340"
              data-name="Path 340"
              d="M3211.988,45.591H3228.9c-1.433,7.69-5.954,13.094-11.83,17.578-8.67,6.618-18.746,10.039-29.294,11.954-21.013,3.806-41.779,2.835-62.031-4.374-7.964-2.835-15.361-6.746-21.338-12.856-11.592-11.839-11.285-28.016.976-39.315,9.155-8.432,20.472-12.847,32.449-15.412,22.959-4.914,45.74-4.369,68.008,3.632,8.459,3.041,15.888,7.754,20.7,15.7.966,1.6,1.617,3.389,2.625,5.542-5.625,0-10.708.041-15.787-.05a2.369,2.369,0,0,1-1.832-1.095c-3.078-8.533-9.985-13.122-17.816-16.112-21.65-8.281-43.253-8.464-64.1,2.56-10.025,5.3-16.973,13.309-17.019,25.41-.041,10.818,6.183,18.063,14.638,23.761,11.583,7.8,24.723,9.613,38.3,9.545,9.6-.05,18.938-1.415,27.8-5.386,9.357-4.2,16.524-10.255,18.64-21.073Z"
              transform="translate(-1678.004 0)"
              fill="#fcffac"
            />
            <path
              id="Path_341"
              data-name="Path 341"
              d="M1825.2,5.7c7.191,0,14.207-.092,21.219.087,1.177.032,2.409,1.118,3.476,1.905q42.223,31.19,84.409,62.421c.888.66,1.814,1.273,3.215,2.258V5.881h6.646V77.75c-7.186,0-14.3.073-21.416-.078-1.076-.023-2.212-1-3.187-1.722q-41.969-30.963-83.9-61.967c-1.081-.8-2.194-1.562-3.806-2.707V77.535h-6.664V5.7Z"
              transform="translate(-989.256 -3.072)"
              fill="#fcffac"
            />
            <path
              id="Path_342"
              data-name="Path 342"
              d="M112.462,72.154V5.739h6.481V77.521c-7.2,0-14.312.092-21.425-.092-1.168-.032-2.382-1.159-3.449-1.946Q51.983,44.424,9.925,13.324c-.893-.66-1.818-1.282-3.142-2.212V77.274H0V5.446c4.763,0,9.613.348,14.395-.092,5.464-.5,9.627,1.392,13.919,4.6,26.82,20.092,53.815,39.951,80.759,59.883.884.65,1.814,1.237,3.394,2.308Z"
              transform="translate(0 -2.852)"
              fill="#fcffac"
            />
            <path
              id="Path_343"
              data-name="Path 343"
              d="M290.84,77.725V6.08H396.954v5.006H305.9V37.741h88.449v5.34H305.876V72.462H398v5.262H290.84Z"
              transform="translate(-157.636 -3.289)"
              fill="#fcffac"
            />
            <path
              id="Path_344"
              data-name="Path 344"
              d="M3414.8,6.07h106.109v5.111h-91.151V37.718H3518.2v5.336h-88.467V72.434h92.117v5.345H3414.8V6.07Z"
              transform="translate(-1850.826 -3.284)"
              fill="#fcffac"
            />
            <path
              id="Path_345"
              data-name="Path 345"
              d="M2515.69,6.07h105.046v6.485h-90.02V36.55h89.328v6.691H2530.5V77.774h-14.8V6.07Z"
              transform="translate(-1363.508 -3.284)"
              fill="#fcffac"
            />
            <path
              id="Path_346"
              data-name="Path 346"
              d="M2783.609,43.324V77.839H2768.93V6.13h105.042v6.682h-90.088v23.77h89.438v6.746h-89.713Z"
              transform="translate(-1500.764 -3.316)"
              fill="#fcffac"
            />
            <path
              id="Path_347"
              data-name="Path 347"
              d="M3031.71,6.03h14.528V77.679H3031.71Z"
              transform="translate(-1643.191 -3.262)"
              fill="#fcffac"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Intro;
